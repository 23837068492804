// Function to handle the search icon click event
function handleSearchIconClick(event) {
	event.preventDefault();

	const expandedSearch = $(".expanded-search");
	const searchInput = $(".js-search-input");
	const searchForm = $(".js-form");
	const expandedClass = "expanded-search--is-expanded";

	if (expandedSearch.hasClass(expandedClass)) {
		// If search bar is expanded
		if (searchInput.val()) {
			// If search input is not empty
			searchForm.submit();
			searchInput.val(""); // Clear the search input after submission
		} else {
			expandedSearch.removeClass(expandedClass); // Remove any additional classes from the form
		}
	} else {
		expandedSearch.addClass(expandedClass); // Expand the search bar
	}
}

// Attach the event listener to the search icon
$(".js-search-icon").on("click", handleSearchIconClick);
