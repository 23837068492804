const $hamburger = $('.hamburger');

$hamburger.on("click", function() {
	$hamburger.toggleClass("is-active");

	jQuery('.mobile-menu').toggleClass('is-active');

	if($hamburger.hasClass("is-active")){
		jQuery("body").css("overflow", "hidden");
	} else{
		jQuery("body").css("overflow", "");
	}
});


$('.menu-item-has-children > a').on('click', (e) => {
	e.preventDefault();
	e.stopPropagation();

	// const $this = e.currentTarget;
	const $this = $(e.currentTarget);
	const $li = $this.parent();
	const $submenu = $li.find('.sub-menu');

	$submenu.toggleClass('is-active');
	$li.toggleClass('is-active');
})

