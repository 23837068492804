import { Fancybox } from '@fancyapps/ui';
import { Accordion } from 'bootstrap';

import "./mobileMenu";
import "./subMenu";
import "./accordion";
import "./expandedSearch";
import { initMap } from './map';


Fancybox.bind('[data-fancybox]', {
//
});


$(document).ready( () => {
	if ( $('body').hasClass('page-template-contact') ) {
		console.log('true');
		initMap();
	}
});
