/**
 * Extend Bootstrap Accordion
 */
const myCollapseEl = document.querySelectorAll(".accordion-collapse");

for (let i = 0; i < myCollapseEl.length; i++) {
  myCollapseEl[i].addEventListener("shown.bs.collapse", (event) => {
    const $el = $(myCollapseEl[i]);
    const $parent = $el.closest(".accordion-item");
    $parent.addClass("show");
  });

  myCollapseEl[i].addEventListener("hide.bs.collapse", (event) => {
    const $el = $(myCollapseEl[i]);
    const $parent = $el.closest(".accordion-item");
    $parent.removeClass("show");
  });
}
