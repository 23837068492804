/* eslint-disable */
export function initMap() {
	// The location of Gthbv
	let location = {
		lat: 52.09347,
		lng: 6.64916,
	};
	// The map, centered at Gthbv
	let map = new google.maps.Map(document.getElementById("map"), {
		zoom: 15,
		center: location,

		styles: [
			{
				featureType: "water",
				elementType: "geometry.fill",
				stylers: [
					{
						color: "#d3d3d3",
					},
				],
			},
			{
				featureType: "landscape.natural",
				elementType: "geometry.fill",
				stylers: [
					{
						color: "#e1e1e1",
					},
				],
			},
			{
				featureType: "poi",
				elementType: "labels.icon",
				stylers: [
					{
						color: "#8a8a8a",
					},
				],
			},
			{
				featureType: "transit",
				stylers: [
					{
						color: "#808080",
					},
					{
						visibility: "off",
					},
				],
			},
			{
				featureType: "road.highway",
				elementType: "geometry.stroke",
				stylers: [
					{
						visibility: "on",
					},
					{
						color: "#b3b3b3",
					},
				],
			},
			{
				featureType: "road.highway",
				elementType: "geometry.fill",
				stylers: [
					{
						color: "#ffffff",
					},
				],
			},
			{
				featureType: "road.local",
				elementType: "geometry.fill",
				stylers: [
					{
						visibility: "on",
					},
					{
						color: "#ffffff",
					},
					{
						weight: 1.8,
					},
				],
			},
			{
				featureType: "road.local",
				elementType: "geometry.stroke",
				stylers: [
					{
						color: "#d7d7d7",
					},
				],
			},
			{
				featureType: "administrative",
				elementType: "geometry",
				stylers: [
					{
						color: "#a7a7a7",
					},
				],
			},
			{
				featureType: "road.arterial",
				elementType: "geometry.fill",
				stylers: [
					{
						color: "#ffffff",
					},
				],
			},
			{
				featureType: "road.arterial",
				elementType: "geometry.fill",
				stylers: [
					{
						color: "#ffffff",
					},
				],
			},
			{
				featureType: "landscape",
				elementType: "geometry.fill",
				stylers: [
					{
						visibility: "on",
					},
					{
						color: "#efefef",
					},
				],
			},
			{
				featureType: "road",
				elementType: "labels.text.fill",
				stylers: [
					{
						color: "#696969",
					},
				],
			},
			{
				featureType: "water",
				elementType: "labels.text.fill",
				stylers: [
					{
						color: "#696969",
					},
				],
			},
			{
				featureType: "administrative",
				elementType: "labels.text.fill",
				stylers: [
					{
						visibility: "on",
					},
					{
						color: "#737373",
					},
				],
			},
			{
				featureType: "road.arterial",
				elementType: "geometry.stroke",
				stylers: [
					{
						color: "#d6d6d6",
					},
				],
			},
			{
				featureType: "road",
				elementType: "labels.icon",
				stylers: [
					{
						visibility: "off",
					},
				],
			},
			{},
		],
	});

	// The marker, positioned at Gthbv2
	let marker = new google.maps.Marker({
		position: location,
		map: map,
	});
}
